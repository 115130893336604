<template>
  <div class="container">
    <div class="title">{{ isVerify ? "发送验证码" : "员工预约" }}</div>

    <template v-if="isVerify">
      <div style="margin-top: 40px">
        <div class="label">验证码</div>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 6px;
          "
        >
          <input
            style="margin-top: 0"
            class="input"
            v-model="smsCode"
            type="text"
            :maxlength="6"
            placeholder="请输入验证码"
          />
          <van-button
            class="sms"
            size="small"
            type="primary"
            round
            :disabled="isSend"
            @click="sendSMS"
          >
            <van-count-down
              v-if="isSend"
              :time="time"
              format="ss"
              @finish="isSend = false"
              style="display: inline-block"
            />
            <span v-if="isSend">s</span>
            <span v-else>发送验证码</span>
          </van-button>
        </div>
        <van-divider></van-divider>
      </div>
    </template>

    <template v-else>
      <div style="margin-top: 40px">
        <div class="label">姓名</div>
        <input
          class="input"
          v-model="name"
          type="text"
          placeholder="请输入团检检测人姓名"
        />
        <van-divider></van-divider>
      </div>
      <div>
        <div class="label">手机号码</div>
        <input
          class="input"
          v-model="phone"
          maxlength="11"
          type="tel"
          placeholder="请输入团检检测人手机号"
        />
        <van-divider></van-divider>
      </div>
    </template>

    <div class="button" @click="submit">
      {{ isVerify ? "立即登录" : "验证身份" }}
    </div>

    <van-checkbox
      v-if="isVerify"
      class="agreement"
      v-model="agreement"
      shape="square"
      icon-size="14px"
      checked-color="#00C4B3"
    >
      登录即表明您同意我们的
      <span class="primary" @click.stop="showPopup = true"
        ><span class="primary" @click="ruleType = 'service'">《服务协议》</span
        ><span class="primary" @click="ruleType = 'privacy'"
          >《隐私协议》</span
        ></span
      >
    </van-checkbox>

    <rule :show="showPopup" :type="ruleType" @close="showPopup = false"></rule>

    <template v-if="!isVerify">
      <div class="desc">身份验证通过后将进入下一步</div>
      <div class="tips">
        如显示【查不到团检信息】则代表企业并未购买该员工的团建套餐或录入信息有误，请联系企业或者拨打{{
          $store.state.hospitalId === "HS2022112843"
            ? "赵医生：19520689589"
            : "在线客服：4009003732"
        }}咨询
      </div>
    </template>
  </div>
</template>

<script>
import { SendSMS, GetToken, verifyGroup, verifyGroupSms } from "@/api";
import rule from "@/components/rule.vue";

export default {
  components: {
    rule,
  },
  data() {
    return {
      isSend: false,
      time: 60 * 1000,
      name: "",
      phone: "",
      smsCode: "",
      isVerify: false,
      groupMedicalExaminationId: "",
      agreement: false,
      showPopup: false,
      ruleType: "",
    };
  },
  mounted() {
    this.$setTitle("登录");
    this.$store.commit("setTitle", "");
  },
  methods: {
    async sendSMS() {
      let token = await GetToken();
      this.isSend = true;
      const params = {
        phone: this.phone,
        templeteId: 745178,
        businessType: 10,
        token: token,
      };
      let data = SendSMS(params);
    },

    async submit() {
      if (this.isVerify) {
        if (!this.smsCode.trim()) {
          return this.$toast("请填写验证码");
        }
        if (!this.agreement) {
          return this.$toast("请同意隐私协议");
        }
        const res = await verifyGroupSms({
          code: this.smsCode.trim(),
          phone: this.phone.trim(),
          groupMedicalExaminationId: this.groupMedicalExaminationId,
        });
        if (res && res.length > 0) {
          if (res.length > 1) {
            localStorage.setItem("groupList", JSON.stringify(res));
            this.$router.replace("/group-select");
            return;
          } else {
            this.$store.commit("setToken", res[0].token);
            this.$store.commit("setMobile", res[0].phone);
            this.$store.commit("setEnterpriseCode", res[0].enterpriseCode);

            this.$router.replace(
              "/group-profile?hospitalId=" + this.$store.state.hospitalId
            );
          }
        }
      } else {
        if (!this.name.trim()) {
          return this.$toast("请输入姓名");
        }
        if (!this.$testPhone(this.phone.trim())) {
          return this.$toast("请输入正确的手机号码");
        }
        const res = await verifyGroup({
          name: this.name.trim(),
          phone: this.phone.trim(),
        });
        this.groupMedicalExaminationId = res.groupMedicalExaminationId;
        this.isVerify = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  height: 100%;
  padding: 0 30px;
  background: url("../../assets/group-login-bg.png") 100% 100% no-repeat;
  background-size: 100% 100%;
  background-color: #fff;
}

.title {
  margin-top: 100px;
  font-size: 24px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #2c2f37;
  line-height: 28px;
}

.label {
  font-size: 14px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #2c2f37;
}

.input {
  margin-top: 10px;
  font-size: 15px;
  width: 100%;
  background-color: transparent;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-top: 25px;
  width: 100%;
  height: 40px;
  background: #00c4b3;
  border-radius: 50px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
}

.desc {
  margin-top: 20px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #878d99;
  text-align: center;
}

.sms {
  flex-shrink: 0;
  width: 84px;
  color: #00c4b3;
  border: 1px solid #00c4b3;
  background: rgba(0, 196, 179, 0.1);
  .van-count-down {
    color: #00c4b3;
  }
}

.tips {
  margin-top: 50px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #878d99;
  text-align: center;
}

.agreement {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
</style>